<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TestBaseVue',
};
</script>

<style scoped>

</style>
